<script setup>

const backendStore = useBackendStore();
const {isEmbed} = backendStore;
const leasing = useLeasingParams();
const {onWindowEvent} = useWindowEvents();

const expandPage = (expand) => {
  const app = document.documentElement;

  expand
    ? app.classList.add('group', 'body-expanded')
    : app.classList.remove('group', 'body-expanded')
}

onWindowEvent('resize', () => {
  expandPage(leasing.leasingDrawerOpened.value);
})

watch(
  () => leasing.leasingDrawerOpened.value,
  (opened) => {
    expandPage(opened);
  }
)
</script>

<template>
  <AppHeaderMinimal v-if="!isEmbed">
    <div class="py-2">
      <CarFinancing />
    </div>
  </AppHeaderMinimal>
  <slot />
  <AppFooterMinimal v-if="!isEmbed" />
  <PoweredByVehis v-else />
  <Blend v-if="!isEmbed" />
</template>
